<template>
  <el-dialog :append-to-body="true" :close-on-click-modal="false" :visible.sync="dialog" :title="title" width="700px" @closed="form = null; auditForm = null;">
    <template v-if="form">
      <template v-if="editable">
        <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="84px" label-suffix=":">
          <div class="h">
            <el-form-item prop="distributorId" label="经销商">
              <quick-select v-model="form.distributorId" filterable value-field="enterpriseId" url="api/distributor" :filterMethod="(s, d) => {
                return s.filter(o => {
                  return (o.erpId || '').toLowerCase().indexOf(d) >= 0 || (o.name || '').toLowerCase().indexOf(d) >= 0;
                });
              }" auto-select-first-option placeholder="请选择经销商" @change="loadBalance" style="width: 230px;">
                <template slot-scope="scope">（{{scope.row.erpId}}）{{scope.row.name}}</template>
              </quick-select>
            </el-form-item>
            <el-form-item label="当前返利余额" label-width="116px">
              <div v-if="form.distributorId">
                <span class="fc-g" v-if="balanceLoading">正在查询中…</span>
                <b :class="balance < 0 ? 'fc-e' : ''" v-else>{{$price(balance)}}</b>
              </div>
              <div class="fc-g" v-else>请先选择经销商</div>
            </el-form-item>
          </div>
          <div class="h">
            <el-form-item prop="inoutType" label="出入类型">
              <el-select v-model="form.inoutType" filterable style="width: 230px">
                <el-option v-for="(value,key) in inoutTypes" :key="key" :label="value" :value="key" />
              </el-select>
            </el-form-item>
            <el-form-item prop="inoutType" label="返利类型" label-width="116px">
              <quick-select v-model="form.rebateTypeId" url="api/rebateType" auto-select-first-option filterable style="width: 230px" />
            </el-form-item>
          </div>
          <div class="h">
            <el-form-item prop="money" label="金额">
              <price-input v-model="form.money" :min="0" :max="999999999" :step-ratio="10000" style="width: 230px" />
            </el-form-item>
            <el-form-item prop="expireTime" label="到期日期" label-width="116px">
              <el-date-picker type="date" v-model="form.expireTime" value-format="timestamp" :clearable="false" style="width: 230px" />
            </el-form-item>
          </div>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="form.info" :maxlength="150" :rows="3" resize="none"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="text" @click="dialog = false">取消</el-button>
          <el-button type="primary" size="mini" :loading="saving" @click="doSubmit">保存</el-button>
        </div>
      </template>
      <template v-else>
        <el-form ref="form" :model="form" label-width="94px" label-position="right" label-suffix=":">
          <div class="h e">
            <div style="width: 50%">
              <form-info-item label="申请单号">{{form.formCode}}</form-info-item>
              <form-info-item label="当前返利余额">
                <span class="fc-g" v-if="balanceLoading">正在查询中…</span>
                <b :class="balance < 0 ? 'fc-e' : ''" v-else>{{$price(balance)}}</b>
              </form-info-item>
              <form-info-item label="申请返利金额">
                <b :class="form.inoutType === 0 ? 'fc-s' : 'fc-e'">{{$price(form.money)}}</b>
              </form-info-item>
              
              <form-info-item label="添加人">{{form.createBy}}</form-info-item>
              <form-info-item label="审核人">{{form.checkerName}}</form-info-item>
              <form-info-item label="到期日期">{{new Date(form.expireTime).format("yyyy-MM-dd")}}</form-info-item>
            </div>
            <div class="flex">
              <form-info-item label="经销商">{{form.distributorName}}</form-info-item>
              <form-info-item label="出入类型">
                <span :class="form.inoutType === 0 ? 'fc-s' : 'fc-e'">{{inoutTypes[form.inoutType]}}</span>
              </form-info-item>
              <form-info-item label="返利类型">{{form.rebateTypeName}}</form-info-item>              
              
              <form-info-item label="添加时间">{{new Date(form.createAt).format()}}</form-info-item>
              
              <form-info-item label="审核时间">{{new Date(form.checkerTime).format()}}</form-info-item>         
              <form-info-item label="当前状态" class="flex">
                <dot same :type="status[form.status].type">{{status[form.status].label}}</dot>
              </form-info-item>
            </div>
          </div>
          <form-info-item label="备注">{{form.info}}</form-info-item>
          

          <template v-if="form.status === 0 && this.auditable">
            <el-divider />
            <div class="h c r">
              <el-button type="primary" :loading="auditing" @click="doAudit(true)">同意</el-button>
              <el-button type="danger" :loading="auditing" @click="doAudit(false)">拒绝</el-button>
            </div>
          </template>
          <template v-else-if="form.status === 1 && action === 'extend'">
            <el-divider content-position="left">延期</el-divider>
            <div class="h s">
              <el-form-item label="到期日期延长至" label-width="118px">
                <el-date-picker type="date" v-model="form.extendExpireTime" :picker-options="extendExpireOptions" value-format="timestamp" :clearable="false" style="width: 160px" />
              </el-form-item>
              <el-form-item label-width="10px">
                <el-button type="primary" :loading="saving" :disabled="form.extendExpireTime === form.expireTime" @click="doExtend">确定</el-button>
              </el-form-item>
            </div>
          </template>
        </el-form>
      </template>
    </template>
  </el-dialog>
</template>
      
      
<script>
import checkPermission from "@/utils/permission";
import { add, edit, audit, delay, getDistributorBalance } from "@/api/rebate";
export default {
  data() {
    return {
      saving: false,
      auditing: false,
      dialog: false,
      auditable: false,
      balanceLoading: false,
      balance: 0,
      form: null,
      action: null,
      inoutTypes: ["入账", "出账"],
      sourceTypes: ["其它", "采购"],
      rules: {
        distributorId: [{ required: true, message: "请选择经销商" }],
        money: [{ required: true, message: "请填写收款金额" }],
        expireTime: [{ required: true, message: "请选择到期日期" }]
      },
      status: [
        {
          type: "info",
          label: "待审批"
        },
        {
          type: "success",
          label: "已通过"
        },
        {
          type: "danger",
          label: "未通过"
        }
      ]
    };
  },
  computed: {
    extendExpireOptions() {
      if (this.form && this.form.expireTime) {
        return {
          disabledDate: new Function(
            "d",
            `return d.getTime() <= ${this.form.expireTime};`
          )
        };
      } else {
        return {};
      }
    },
    title() {
      if (this.form) {
        if (this.editable) {
          if (this.form.id) {
            return "修改返利申请";
          } else {
            return "新增返利申请";
          }
        } else if (this.auditable) {
          return "审批返利申请";
        } else {
          return "返利申请详情";
        }
      }
      return "正在加载…";
    },
    editable() {
      return (
        this.form &&
        this.checkPermission(["REBATEAPPLY_ALL", "REBATEAPPLY_EDIT"]) &&
        this.form.status === 0 &&
        !this.auditable
      );
    }
  },
  methods: {
    checkPermission,
    doSubmit() {
      this.$refs.form &&
        this.$refs.form.validate().then(_ => {
          let d = new Date(this.form.expireTime);
          d.expireTime = new Date(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            23,
            59,
            59
          );
          this.saving = true;
          (this.form.id ? edit : add)(this.form)
            .then(res => {
              this.$notify({
                title: "添加成功",
                type: "success",
                duration: 2500
              });
              this.dialog = false;
              this.form = null;
              this.$parent.init();
            })
            .finally(_ => {
              this.saving = false;
            });
        });
    },
    doAudit(pass) {
      this.$confirm(`确定${pass ? "同意" : "拒绝"}该返利申请吗？`, "操作提示", {
        type: "warning"
      }).then(_ => {
        this.auditing = true;
        audit({ id: this.form.id, result: pass })
          .then(res => {
            this.$notify({
              title: "审批成功",
              type: "success",
              duration: 2500
            });
            this.dialog = false;
            this.$parent.init();
          })
          .finally(_ => {
            this.auditing = false;
          });
      });
    },
    doExtend() {
      this.$confirm("您确定要延期到期日期吗？", "操作确认", {
        type: "warning"
      }).then(_ => {
        this.saving = true;
        let d = new Date(this.form.extendExpireTime);
        let oldEx = new Date(this.form.expireTime);
        let now = new Date();
        delay({
          id: this.form.id,
          expireTime: new Date(
            d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            23,
            59,
            59
          ).getTime(),
          onlyDelayTime:oldEx > now,
        })
          .then(res => {
            this.$notify({
              title: "到期日期延期成功",
              type: "success",
              duration: 2500
            });
            this.dialog = false;
            this.$parent.init();
          })
          .finally(_ => {
            this.saving = false;
          });
      });
    },
    loadBalance() {
      if (this.form && this.form.distributorId) {
        this.balanceLoading = true;
        getDistributorBalance(this.form.distributorId)
          .then(res => {
            this.balance = res;
          })
          .finally(_ => {
            this.balanceLoading = false;
          });
      }
    },
    resetForm(form, auditable = false, action = null) {
      this.auditable = form && form.id && auditable;
      this.action = action;
      let now = new Date();
      this.form = form || {
        distributorId: null,
        rebateTypeId: null,
        sourceType: 1,
        inoutType: 0,
        expireTime: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59
        ).getTime(),
        money: 0,
        info: "",
        status: 0
      };
      if (this.form.distributorId) {
        this.loadBalance();
      }
      this.dialog = true;
    }
  }
};
</script>

